<template>
  <div>
    <BackButton name="Companies" route="/companies" />
    <div>
      <h2 class="page-title">{{ company.companies[0].company_name }}</h2>
    </div>
    <b-container fluid="lg">
      <b-row>
        <b-col xl="9">
          <h5 class="mt-2 mb-3" style="font-weight: bold">Client audits</h5>
          <vue-good-table
            :rows="company.companies[0].company_client_audits"
            styleClass="vgt-table"
            :columns="columns"
          >
            <div style="text-align: center" slot="emptystate">No audits</div>
            <template slot="table-row" slot-scope="props">
              <span
                v-if="props.column.field == 'actions'"
                style="margin-left: auto; text-align: right !important"
              >
                <b-button
                  variant="outline-dark"
                  size="sm"
                  v-if="props.column.field === 'actions'"
                  style="margin-top: -10px; margin-bottom: -10px"
                  :to="'/audits/' + props.row.id"
                >
                  <b-icon-arrow-right></b-icon-arrow-right>
                </b-button>
              </span>
              <span v-else-if="props.column.field === 'findings'">
                {{ props.row.closedFindings.aggregate.count }} /
                {{ props.row.totalFindings.aggregate.count }}
              </span>
              <span v-else-if="props.column.field === 'status'">
                <i :class="auditStatusIcon(props.row.audit_closed)"></i>
                {{ props.row.audit_closed ? "Closed" : "Open" }}
              </span>
              <span v-else-if="props.column.field === 'audit_expiry_date'">
                {{ props.row.audit_expiry_date | formatDate }}
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>

          <h5 class="mt-4 mb-3" style="font-weight: bold">Auditee audits</h5>
          <vue-good-table
            :rows="company.companies[0].company_auditee_audits"
            styleClass="vgt-table"
            :columns="columns"
          >
            <div style="text-align: center" slot="emptystate">No audits</div>
            <template slot="table-row" slot-scope="props">
              <span
                v-if="props.column.field == 'actions'"
                style="margin-left: auto; text-align: right !important"
              >
                <b-button
                  variant="outline-dark"
                  size="sm"
                  style="
                    margin-top: -10px;
                    margin-bottom: -10px;
                    margin-right: 5px;
                  "
                  v-if="
                    props.column.field == 'actions' &&
                    !showReportButton(props.row)
                  "
                  v-b-tooltip.hover.left="'Report (PDF)'"
                  @click="report(props.row, props.index, $event.target)"
                >
                  <b-icon-file-earmark-text></b-icon-file-earmark-text>
                </b-button>
                <b-button
                  variant="outline-dark"
                  size="sm"
                  v-if="props.column.field === 'actions'"
                  style="margin-top: -10px; margin-bottom: -10px"
                  :to="'/audits/' + props.row.id"
                >
                  <b-icon-arrow-right></b-icon-arrow-right>
                </b-button>
                <b-button
                  variant="outline-dark"
                  size="sm"
                  v-if="
                    props.column.field === 'actions' &&
                    $auth.user[`https://skyswarm.sky360.com.au/role`] ==
                      `external`
                  "
                  style="margin-top: -10px; margin-bottom: -10px"
                  :to="'/audits/' + props.row.id"
                >
                  <b-icon-arrow-right></b-icon-arrow-right>
                </b-button>
              </span>
              <span v-else-if="props.column.field === 'findings'">
                {{ props.row.closedFindings.aggregate.count }} /
                {{ props.row.totalFindings.aggregate.count }}
              </span>
              <span v-else-if="props.column.field === 'status'">
                <i :class="auditStatusIcon(props.row.audit_closed)"></i>
                {{ props.row.audit_closed ? "Closed" : "Open" }}
              </span>
              <span v-else-if="props.column.field === 'audit_expiry_date'">
                {{ props.row.audit_expiry_date | formatDate }}
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </b-col>
        <b-col xl="3">
          <h5 class="mt-2 mb-3" style="font-weight: bold">Accounts</h5>
          <!-- <vue-good-table :rows="company.companies[0].company_users" :columns="userColumns"></vue-good-table> -->
          <b-list-group>
            <b-list-group-item
              style="padding-left: 12px; padding-right: 12px"
              v-for="user in company.companies[0].company_users"
              :key="user.id"
            >
              <b-avatar
                variant="secondary"
                :text="
                  user.user_name
                    .split(' ')
                    .map(function (item) {
                      return item[0];
                    })
                    .join('')
                "
                class="mr-3"
              ></b-avatar>
              <span class="mr-auto">{{ user.user_name }}</span>
            </b-list-group-item>
            <b-list-group-item
              style="padding-left: 12px; padding-right: 12px"
              v-if="!company.companies[0].company_users.length"
            >
              <span>No accounts found</span>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import BackButton from "../components/BackButton";
export default {
  name: "Audits",
  components: {
    BackButton,
  },
  data() {
    return {
      fill: { gradient: ["red", "green", "blue"] },
      company: undefined,
      columns: [
        {
          field: "audit_name",
          label: "Audit",
        },
        {
          field: "findings",
          label: "Closed / total findings",
        },
        {
          field: "status",
          label: "Status",
        },
        {
          field: "audit_expiry_date",
          label: "Expiry date",
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "dd/MM/yyyy",
        },
        {
          field: "actions",
          label: "",
          sortable: false,
          type: "number",
          width: "105px",
        },
      ],
      userColumns: [
        {
          field: "user_name",
          label: "Name",
        },
        {
          field: "id",
          label: "id",
        },
      ],
      fields: [
        {
          key: "user_name",
          label: "Name",
          sortable: true,
        },
        {
          key: "user_email",
          label: "Email address",
          sortable: true,
        },
        {
          key: "user_company_name",
          label: "Company",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    progress(event, progress, stepValue) {
      console.log(stepValue);
    },
    progress_end() {
      console.log("circle prog end");
    },

    auditStatus(status) {
      if (status == false) {
        return "Open";
      } else {
        return "Closed";
      }
    },
    auditStatusIcon(status) {
      if (status === false) {
        return "fa fa-exclamation-circle redicon";
      } else {
        return "fa fa-check-circle greenicon";
      }
    },
    showReportButton(item) {
      return item.audit_report === null ? true : false;
    },
    formatDate(date) {
      if (date != undefined) {
        const day = date.substring(8);
        const month = date.substring(5, 7);
        const year = date.substring(0, 4);
        return `${day}/${month}/${year}`;
      }
    },
  },
  created: async function () {
    const token = await this.$auth.getTokenSilently();
    axios
      .request({
        method: "post",
        url: "https://graphql.api.skyswarm.sky360.com.au/v1/graphql",
        data: {
          query: `query company {
  companies(where: {id: {_eq: "${this.$route.params.companyId}"}}) {
    company_name
    company_users {
      id
      user_name
    }
    company_client_audits {
      id
      audit_name
      audit_closed
      audit_expiry_date
      totalFindings: audit_findings_aggregate {
      aggregate {
        count
      }
    }
    closedFindings: audit_findings_aggregate(where: {_and: {finding_closed: {_eq: true}}}) {
      aggregate {
        count
      }
    }
    }
    company_auditee_audits {
      id
      audit_name
      audit_closed
      audit_expiry_date
      totalFindings: audit_findings_aggregate {
      aggregate {
        count
      }
    }
    closedFindings: audit_findings_aggregate(where: {_and: {finding_closed: {_eq: true}}}) {
      aggregate {
        count
      }
    }
    }
  }
}

`,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => (this.company = response.data.data));
  },
};
</script>

<style>
.fa {
  font-size: 16px;
  padding-right: 2px;
}
.redicon {
  color: red;
}
.greenicon {
  color: limegreen;
}
.table {
  background-color: #ffffff !important;
}
.actions-column {
  max-width: 60px;
  text-align: right;
}
tr {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.container-lg {
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  max-width: 100%;
}
</style>
