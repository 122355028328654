<template>
  <div>
    <div>
      <h2 style="padding-bottom: 10px; display: inline-block">Companies</h2>
      <b-button
        v-b-modal.modal-new-audit
        style="float: right; margin-top: 0px; margin-right: 0 !important"
        variant="outline-dark"
        size="md"
        class="mr-1"
        >Add company</b-button
      >
      <b-modal id="modal-new-account" title="Add account">
        <b-form @submit.stop.prevent>
          <label for="new-account-name">Name</label>
          <b-form-input
            v-model="newCompanyName"
            :state="validation"
            id="new-company-name"
          ></b-form-input>
        </b-form>
      </b-modal>
    </div>
    <b-table
      sticky-header
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :items="companies"
      show-empty
      borderless
      primary-key="companies.data.data.company.id"
      class="table"
      head-variant="light"
      style="
        background-color: #fff;
        background-clip: border-box;
        border-radius: 0.25rem;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      "
    >
    </b-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Audits",
  components: {},
  data() {
    return {
      fill: { gradient: ["red", "green", "blue"] },
      companies: undefined,
      fields: [
        {
          key: "user_name",
          label: "Name",
          sortable: true,
        },
        {
          key: "user_email",
          label: "Email address",
          sortable: true,
        },
        {
          key: "user_company_name",
          label: "Company",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    progress(event, progress, stepValue) {
      console.log(stepValue);
    },
    progress_end() {
      console.log("circle prog end");
    },
    auditStatus(status) {
      if (status == false) {
        return "Open";
      } else {
        return "Closed";
      }
    },
    auditStatusIcon(status) {
      if (status === false) {
        return "fa fa-exclamation-circle redicon";
      } else {
        return "fa fa-check-circle greenicon";
      }
    },
    formatDate(date) {
      if (date != undefined) {
        const day = date.substring(8);
        const month = date.substring(5, 7);
        const year = date.substring(0, 4);
        return `${day}/${month}/${year}`;
      }
    },
  },
  created: async function () {
    const token = await this.$auth.getTokenSilently();
    const { call } = await axios({
      method: "post",
      url: "https://graphql.api.skyswarm.sky360.com.au/v1/graphql",
      data: {
        query: `query MyQuery {
  companies {
    company_name
    id
  }
}`,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(
      (response) =>
        (this.companies = response.data.data.companies.filter(function (
          company
        ) {
          return (
            company !==
            {
              company_name: "Select a company...",
              id: "00000000-0000-0000-0000-000000000000",
            }
          );
        }))
    );
    console.log(call);
  },
};
</script>

<style>
.fa {
  font-size: 16px;
  padding-right: 2px;
}
.redicon {
  color: red;
}
.greenicon {
  color: limegreen;
}
.table {
  background-color: #ffffff !important;
}
.actions-column {
  max-width: 60px;
  text-align: right;
}
tr {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
</style>
