<template>
  <div>
    <div>
      <h2 style="padding-bottom: 10px; display: inline-block">Accounts</h2>
      <b-button
        v-b-modal.modal-new-account
        style="float: right; margin-top: 0px; margin-right: 0 !important"
        variant="outline-dark"
        size="md"
        :to="/accounts/"
        class="mr-1"
        >Add account</b-button
      >
      <b-modal
        id="modal-new-account"
        title="Add account"
        ok-title="Create account"
        @ok="handleOk"
      >
        <b-form @submit.stop.prevent>
          <label for="new-account-name">Name</label>
          <b-form-input
            v-model="newUserName"
            :state="validation"
            id="new-account-name"
          ></b-form-input>
          <label for="new-account-email">Email address</label>
          <b-form-input
            v-model="newUserEmail"
            :state="validation"
            id="new-account-email"
          ></b-form-input>
        </b-form>
      </b-modal>
    </div>
    <transition name="slide-fade">
      <b-table
        ref="accountsTable"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :items="accounts"
        :fields="fields"
        show-empty
        borderless
        primary-key="accounts.data.data.users.id"
        class="table"
        head-variant="light"
        style="
          background-color: #fff;
          background-clip: border-box;
          border-radius: 0.25rem;
          border-top-left-radius: 0.25rem;
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        "
      >
      </b-table>
    </transition>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Audits",
  components: {},
  data() {
    return {
      fill: { gradient: ["red", "green", "blue"] },
      accounts: undefined,
      newUserName: undefined,
      newUserEmail: undefined,
      showTable: true,
      fields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "email",
          label: "Email address",
          sortable: true,
        },
        {
          key: "company_name",
          label: "Company",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    progress(event, progress, stepValue) {
      console.log(stepValue);
    },
    progress_end() {
      console.log("circle prog end");
    },
    handleOk() {
      console.log(`${this.newUserName} ${this.newUserEmail}`);
      this.createNewAccRequest();
    },
    async createNewAccRequest() {
      const token = await this.$auth.getTokenSilently();
      const { call } = await axios({
        method: "post",
        url: "https://graphql.api.skyswarm.sky360.com.au/v1/graphql",
        data: {
          query: `mutation MyMutation {
  insert_users(objects: {user_name: "${this.newUserName}", user_email: "${this.newUserEmail}"}) {
    returning {
      user_name
      user_email
      id
    }
  }
}`,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) =>
        console.log(
          this.accounts.data.data.users.unshift(
            response.data.data.insert_users.returning[0]
          )
        )
      );
      console.log(call);
      this.newUserEmail = "";
      this.newUserName = "";
    },
  },
  created: async function () {
    const token = await this.$auth.getTokenSilently();
    const { call } = await axios({
      method: "post",
      url: "https://graphql.api.skyswarm.sky360.com.au/v1/graphql",
      data: {
        query: `query {
  getAccounts {
    acc_status
    company_id
    company_name
    id
    name
    role
    email
  }
}

`,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => (this.accounts = response.data.data.getAccounts));
    console.log(call);
  },
};
</script>

<style>
.redicon {
  color: red;
}
.greenicon {
  color: limegreen;
}
.table {
  background-color: #ffffff !important;
}
.actions-column {
  max-width: 60px;
  text-align: right;
}
tr {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
</style>
